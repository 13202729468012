<template>
  <div class="ranking">
    <ImageHeader :title="$t('MANAGER.RANKING_FINAL')" />
    <div class="is-10 container-data container">
      <div class="container is-10 data">
        <div class="columns personal-data">
          <div class="is-5 is-offset-1 column has-text-left">
            <p class="title-m black bold pb-4">{{ user.nombre }} {{ user.primer_apellido }} {{ user.segundo_apellido }}
            </p>
          </div>
          <div class="is-2 is-offset-3 column has-text-right">
            <p class="title-m black bold pb-2">
              <span><span v-if="alumnos.length < 10">0</span><span>{{ alumnos.length }}</span>
                <span style="padding-left: 5px">{{
                  $t("MANAGER.USERS")
                }}</span></span>
            </p>
            <p class="text-m text-primary bold is-clickable" @click="downloadRanking()" v-if="alumnos.length > 0">
              <font-awesome-icon :icon="['fal', 'file-export']" />
              {{ $t("MANAGER.DOWNLOAD_RANKING") }}
            </p>
          </div>
        </div>
        <div class="columns selectors">
          <div class="is-4 is-offset-1 column has-text-left custom-select">
            <b-field>
              <b-select :placeholder="$t('STIMULUS.DATA.PROVINCE')" v-model="filters.provincia" @input="filter(true)">
                <option v-for="province in provincias" :key="province.value" :value="province.value">
                  {{ province.value }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="is-4 is-offset-2 column has-text-right custom-select">
            <b-field>
              <b-select :placeholder="$t('LOGIN.LANGUAGE')" icon="globe" icon-pack="fal" v-model="filters.idioma"
                @input="filter()">
                <option v-for="idioma in idiomas" :key="idioma.value" :value="idioma.value">
                  {{ $t("LOGIN.LANGUAGES." + idioma.key) }}</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns" v-if="alumnos">
          <div class="column is-10 is-offset-1">
            <table class="table">
              <thead>
                <tr>
                  <th v-for="(column, index) in columns" :key="index" scope="col"
                    :class="{ 'has-text-centered': column.centerTitle }">
                    <b class="text-m">{{ $t(column.title) }}</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(alumno, index) in alumnos" :key="index" :class="{
                  'first-tr': index === 0,
                  'second-tr': index === 1,
                  'third-tr': index === 2,
                }">
                  <td class="td-table-ranking has-text-centered td-position" :class="{
                    first: index === 0,
                    second: index === 1,
                    third: index === 2,
                  }">
                    <img src="../assets/icons/oro.svg" v-if="index === 0" />
                    <img src="../assets/icons/plata.svg" v-if="index === 1" />
                    <img src="../assets/icons/bronce.svg" v-if="index === 2" />
                    {{ index + 1 }}
                  </td>
                  <td class="td-table-ranking has-text-centered">
                    {{ alumno.relato_id }}
                  </td>
                  <td class="td-table-ranking has-text-centered">
                    {{ alumno.usuario_id }}
                  </td>
                  <td class="td-table-ranking has-text-centered">{{ alumno.nombre }} {{
                    alumno.primer_apellido }}
                    {{ alumno.segundo_apellido }}</td>
                  <td class="td-table-ranking has-text-centered">{{ alumno.email }}</td>
                  <td class="td-table-ranking has-text-centered">{{ alumno.centro }}</td>
                  <td class="td-table-ranking has-text-centered td-puntuacion">
                    {{ alumno.puntuacion }}
                  </td>
                  <td class="td-table-ranking has-text-centered td-download is-clickable" @click="downloadRelato(alumno.relato_id)">
                    <font-awesome-icon class="icono" :icon="['fal', 'print']" />
                    {{ $t("EVALUATOR.STORY.PRINT") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader.vue";
import { AREAS } from "../constants.js";
export default {
  name: "ranking",
  components: {
    ImageHeader,
  },
  data() {
    return {
      columns: [{
        title: "EVALUATOR.POSITION",
        centerTitle: true,
      },
      {
        title: "MANAGER.NUMBER_RELATO",
        centerTitle: true,
      }, {
        title: 'MANAGER.NUMBER_USER',
        centerTitle: true
      }, {
        title: 'REGISTER.NAME',
        centerTitle: true
      }, {
        title: 'REGISTER.EMAIL',
        centerTitle: true
      }, {
        title: 'REGISTER.CENTER',
        centerTitle: true
      }, {
        title: 'EVALUATOR.MARK',
        centerTitle: true
      }],
      user: {},
      provincias: [],
      idiomas: [],
      alumnos: [],
      filters: {
        provincia: "",
        idioma: ""
      },
    };
  },
  created() {
    this.$store.commit("setShowSpinner", true);
    this.$store.dispatch("getUser").then((user) => {
      this.user = user;
      this.provincias = AREAS[user.area].provincias;
      this.idiomas = AREAS[user.area].provincias[0].idiomas;
      this.filters.provincia = this.provincias[0].value;
      this.filters.idioma = this.idiomas[0].value;

      this.$store.dispatch("getRanking", {
        provincia: this.filters.provincia,
        idioma: this.filters.idioma
      }).then(alumnos => {
        this.alumnos = alumnos;
      });
      this.$store.commit("setShowSpinner", false);
    }).catch(error => {
      this.$store.commit("setShowSpinner", false);
    });
  },
  methods: {
    filter(changeProvincia = false) {
      this.$store.commit("setShowSpinner", true);
      if (changeProvincia) {
        this.idiomas = AREAS[this.user.area].provincias.find(p => p.value === this.filters.provincia).idiomas;
        this.filters.idioma = this.idiomas[0].value;
      }
      this.$store.dispatch("getRanking", {
        provincia: this.filters.provincia,
        idioma: this.filters.idioma
      }).then(alumnos => {
        this.alumnos = alumnos;
        this.$store.commit("setShowSpinner", false);
      });
    },
    downloadRelato(relatoId) {
      this.$store.commit("setShowSpinner", true);
      this.$store.dispatch("downloadRelato", { relato_id: relatoId }).then((response) => {
        const file = new Blob([response], {
          type: "application/pdf",
        });
        this.$store.commit("setShowSpinner", false);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = `relato_${relatoId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    downloadRanking() {
      this.$store.commit("setShowSpinner", true);
      this.$store.dispatch("downloadRanking", {
        provincia: this.filters.provincia,
        idioma: this.filters.idioma
      }).then(response => {
        console.log('response', response);
        const file = new Blob([response], {
          type: "text/csv;charset=utf-8",
        });
        this.$store.commit("setShowSpinner", false);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = `ranking_${this.filters.provincia.toLowerCase()}_${this.filters.idioma}_${new Date().getFullYear()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.$store.commit("setShowSpinner", false);
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.first {
  color: #edd144;
  padding-top: 2em !important;
}

.second {
  color: #cbcbcb;
  padding-top: 2em !important;
}

.third {
  color: #c59359;
  padding-top: 2em !important;
}

.personal-data {
  padding-top: 25px;
}

.selectors {
  background-color: $primary;
  margin-left: 0px;
  margin-right: 0px;
}

.container-data {
  margin-top: -150px;
  padding-bottom: 120px;
}

.container.data {
  background-color: white;
}

.count-manager {
  position: relative;
  top: 20px;
}

.titulo-seccion {
  margin-top: 10px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;

  div {
    min-width: fit-content;
  }

  div:last-child {
    width: -webkit-fill-available;
    margin-left: 5%;
    height: 20px;
  }
}

hr {
  margin: 0.10rem 0;
}

.table {
  width: 100%;
  margin-bottom: 25px;
  border-collapse: separate;
  border-spacing: 0px 12px;
}


.table td,
.table th {
  border: none;
  padding: 1em 1.25em;
}

.table tbody tr {
  background-color: $background-box;

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.profileImage {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.btn {
  background-color: white;
  height: 50px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  // width: 100%;
  .content-button {
    display: flex;
    align-items: center;
  }

  .icono {
    font-size: 20px;
    color: $primary;
    margin-right: 10px;
  }
}

.icono-modal {
  font-size: 25px;
  margin-right: 20px;
}

.btn-modal {
  background-color: $primary;

  .icono {
    color: white;
  }
}

.parte-superior {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.parte-superior.welcome {
  justify-content: flex-end;
}

.stimulus {
  padding: 40px;
  background-color: white;
  margin-top: 25px;
  border-radius: 10px;
}

.btn-finish {
  background-color: $primary;
  color: white;
  height: 60px;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%;
}

.btn-finish:hover {
  color: white;
}

.td-table-ranking {
  vertical-align: middle;
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.td-position {
  font-weight: bolder;
}

.td-puntuacion {
  font-weight: 600;
  font-size: 1.2rem;
  color: $primary;
}

.first-tr {
  background-color: #eed24440 !important;
  color: #bea837 !important;
  font-weight: bold;
}

.second-tr {
  background-color: #cbcbcb40 !important;
  color: #929292 !important;
  font-weight: bold;
}

.third-tr {
  background-color: #c5935940 !important;
  color: #84623b !important;
  font-weight: bold;
}

.td-download:hover {
  color: $primary;
}
</style>
