<template>
    <div class="contenido">
        <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal>
            <template>
                <div class="container-modal container">
                    <div class="parte-superior">
                        <div>
                            <span>
                                {{currentStudent ? $t('TEACHER.EDIT_STUDENT') : $t('TEACHER.NEW_STUDENT')}}
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div class="columns">
                        <div class="column is-4">
                            <b-field :label="$t('REGISTER.NAME')">
                                <b-input v-model.trim="formStudent.nombre" custom-class="custom-input-add-student"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-4">
                            <b-field :label="$t('REGISTER.SURNAME')">
                                <b-input v-model.trim="formStudent.primer_apellido" custom-class="custom-input-add-student"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-4">
                            <b-field :label="$t('REGISTER.SECOND_SURNAME')">
                                <b-input v-model.trim="formStudent.segundo_apellido" custom-class="custom-input-add-student"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns is-multiline">
                        <div class="column is-12">
                            {{$t('TEACHER.GENERAL_TESTS')}}:
                        </div>
                        <div class="column is-6 prueba-select" :class="{ 'prueba-selected': formStudent.pruebas.find(p => p.prueba_id === prueba.prueba_id)}" v-for="(prueba, index) in pruebas.filter(p => p.idioma === 'es')" :key="'ge' + index" @click="addPrueba(prueba)">
                            <div></div>

                            <div>{{prueba.descripcion}} ({{ formatearFecha(prueba.fecha_inicio) }})</div>
                        </div>
                        <div class="column is-12" v-if="pruebas.filter(p => p.idioma !== 'es').length > 0">
                            {{$t('TEACHER.ESPESIFIC_TESTS')}}:
                        </div>
                        <div class="column is-6 prueba-select" :class="{ 'prueba-selected': formStudent.pruebas.find(p => p.prueba_id === prueba.prueba_id)}" v-for="(prueba, index) in pruebas.filter(p => p.idioma !== 'es')" :key="'es' + index" @click="addPrueba(prueba)">
                            <div></div>

                            <div>{{prueba.descripcion}} ({{ formatearFecha(prueba.fecha_inicio) }})</div>
                        </div>
                    </div>
                    <div class="columns is-multiline mt-3">
                        <div class="column is-12">
                            <b-field>
                                <b-checkbox v-model="formStudent.tieneNecessidadesEspeciales">
                                    <b>{{$t('TEACHER.TEXT_BOLD_SPECIAL')}}</b> {{$t('TEACHER.TEXT_SPECIAL')}}
                                </b-checkbox>
                            </b-field>
                        </div>
                        <div class="column is-12" v-if="formStudent.tieneNecessidadesEspeciales">
                            <b-dropdown
                                position="is-top-right"
                                v-model="formStudent.necesidades_especiales"
                                aria-role="list">
                                <template #trigger>
                                    <b-button
                                        type="btn-dropdown"
                                        icon-pack="fas"
                                        icon-right="chevron-down">
                                       {{ formStudent.necesidades_especiales ? formStudent.necesidades_especiales : $t('TEACHER.SELECT_SPECIAL') }}
                                    </b-button>
                                </template>

                                <b-dropdown-item value="Invidente" aria-role="listitem">
                                    <span>Invidente</span>
                                </b-dropdown-item>

                                <b-dropdown-item value="CI bajo" aria-role="listitem">
                                    <span>CI bajo</span>
                                </b-dropdown-item>

                                <b-dropdown-item value="Dislexia" aria-role="listitem">
                                    <span>Dislexia</span>
                                </b-dropdown-item>

                                <b-dropdown-item value="TDAH" aria-role="listitem">
                                    <span>TDAH</span>
                                </b-dropdown-item>

                                <b-dropdown-item value="Otros" aria-role="listitem">
                                    <span>Otros</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12 is-flex is-justify-content-end">
                            <b-button v-if="currentStudent" @click="confirmDelete()" class="mr-4 btn btn-trash">
                                <font-awesome-icon :icon="['fas', 'trash']" />
                            </b-button>
                            <b-button @click="isModalActive = false" class="mr-4 btn btn-cancel">
                                {{$t('TEACHER.CANCEL')}}
                            </b-button>
                            <b-button :disabled="!this.formStudent.nombre || !this.formStudent.primer_apellido || this.formStudent.pruebas.length === 0" @click="sumbitFormStudent()" class="mr-4 btn btn-add">
                                {{currentStudent ? $t('TEACHER.UPDATE_STUDENT') : $t('TEACHER.ADD_STUDENT')}}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
        <ImageHeader :title="$t('TEACHER.TITLE')" />
        <div class="is-10 datos container">
            <div class="columns is-multiline">
                <div class="is-8 column has-text-left">
                    <div>
                        <p class="titulo-seccion"><b>{{ personalData.name }}</b></p>
                    </div>
                    <div v-if="!isSuperProfesor">
                        <p class="center">{{$t('TEACHER.CENTER')}}: {{ personalData.centerName }}</p>
                    </div>
                </div>
                <div class="column is-4">
                    <b-field>
                        <b-input :placeholder="$t('TEACHER.SEARCH')"
                            type="search"
                            icon-right="search"
                            icon-pack="fal"
                            v-model="studentName"
                            custom-class="custom-input-i">
                        </b-input>
                    </b-field>
                </div>
                <div class="is-12 column header-buttons">
                    <div style="width: 80%;">
                        <div v-if="isSuperProfesor" class="is-flex">
                            <div class="mr-5">
                                <b-field :label="$t('REGISTER.COMMUNITY')">
                                    <b-select
                                        custom-class="custom-input-i"
                                        :placeholder="$t('REGISTER.COMMUNITY')" v-model.trim="filtersForm.comunidad_autonoma"
                                        @input="selectCommunity">
                                        <option v-for="community in communitys" :key="community.id" :value="community.id">
                                        {{ community.name }}</option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="mr-5">
                                <b-field :label="$t('REGISTER.PROVINCE')">
                                    <b-select custom-class="custom-input-i" name="provincia" :placeholder="$t('REGISTER.PROVINCE')"
                                        v-model.trim="filtersForm.provincia" @input="selectProvince">
                                        <option v-for="province in provinces" :key="province.id" :value="province.id">
                                        {{ province.name }}</option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div style="width: 30%;">
                                <b-field :label="$t('REGISTER.CENTER')">
                                    <b-autocomplete
                                        custom-class="custom-input-i"
                                        :data="filteredCenters"
                                        field="name"
                                        v-model="filterName"
                                        :placeholder="$t('REGISTER.CENTER')"
                                        clearable
                                        icon-pack="fal"
                                        open-on-focus
                                        @select="option => selectCentro(option)">
                                        <template #empty>{{$t('REGISTER.NO_RESULTS')}}</template>
                                    </b-autocomplete>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <button class="button btn" @click="downloadCSV()" v-if="students.length > 0">
                        <font-awesome-icon class="mr-2" :icon="['fal', 'arrow-to-bottom']" />
                        {{$t('TEACHER.DOWNLOAD')}}
                    </button>
                    <button class="button btn" :disabled="isSuperProfesor && !filtersForm.centro" v-if="!isReadOnly" @click="openModal(null)">
                        <font-awesome-icon class="mr-2" :icon="['fal', 'plus']" />
                        {{$t('TEACHER.ADD_STUDENT')}}
                    </button>
                </div>
            </div>
            <div class="columns">
                <div class="column is-12">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col" v-if="showCenterColumn" class="has-text-left">
                                <b class="text-m">{{$t('REGISTER.CENTER')}}</b>
                            </th>
                            <th v-for="(column, index) in columns" :key="index" scope="col" :class="{
                            'has-text-left': column.leftTitle,
                            'has-text-centered': column.centerTitle
                            }">
                                <b class="text-m">{{ $t(column.title) }}</b>
                            </th>
                            <th v-if="students.length > 0" class="icons-actions"></th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(student, index) in students" :key="index" class="text-m gray bold">
                                <td v-if="showCenterColumn" class="has-text-left">
                                    {{ student.centro }}
                                </td>
                                <td class="has-text-left">
                                    {{ student.nombre }} {{ student.primer_apellido }} {{ student.segundo_apellido }}
                                </td>
                                <td class="has-text-centered">
                                    {{ student.id }}
                                </td>
                                <td class="has-text-centered">
                                    {{ student.password_text }}
                                </td>
                                <td class="has-text-left rounded-border">
                                    {{ student.pruebas.split(', ').map(p => pruebas.find(pA => pA.prueba_id === Number(p)).descripcion + ' (' + formatearFecha(pruebas.find(pA => pA.prueba_id === Number(p)).fecha_inicio) + ')').join(', ') }}
                                </td>
                                <td class="has-text-right icons-actions">
                                    <font-awesome-icon v-if="!isReadOnly" :icon="['fas', 'edit']" @click="openModal(student)"/>
                                    <font-awesome-icon v-if="downloadDiplomes" style="--fa-secondary-color: #E61D2B; --fa-secondary-opacity: 0.60" class="ml-5" :icon="['fad', 'file-certificate']" />
                                </td>
                            </tr>
                            <tr v-if="students.length === 0">
                                <td :colspan="columns.length + (showCenterColumn ? 1 : 0 )" class="has-text-centered">
                                    {{$t('TEACHER.NO_STUDENTS')}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </div>
        </div>
    </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader.vue";
import { ROLES, FORMAT_ROL, FASES } from "../constants.js";
import jwt_decode from "jwt-decode";
import sha1 from "crypto-js/sha1";

export default {
    name: "portalProfesor",
    components: {
        ImageHeader
    },
    data() {
        return {
            personalData: {
                name: null,
                phone: null,
                email: null,
                province: null,
                community: null,
                center: null,
                centerName: null
            },
            columns: [
                {
                    title: this.$t("TEACHER.NAME"),
                    leftTitle: true,
                },
                {
                    title: this.$t("TEACHER.CODE"),
                    centerTitle: true,
                },
                {
                    title: this.$t("TEACHER.PASSWORD"),
                    centerTitle: true,
                },
                {
                    title: this.$t("TEACHER.TESTS"),
                    leftTitle: true,
                },
            ],
            studentsList: [],
            formStudent: {
                nombre: null,
                primer_apellido: null,
                segundo_apellido: null,
                pruebas: [],
                necesidades_especiales: null,
                tieneNecessidadesEspeciales: false
            },
            isEditing: false,
            isSuperProfesor: false,
            showCenterColumn: false,
            pruebas: [],
            isModalActive: false,
            currentStudent: null,
            downloadDiplomes: false,
            studentName: null,
            isReadOnly: false,
            communitys: [],
            provinces: [],
            centers: [],
            filterName: "",
            filtersForm: {
                comunidad_autonoma: null,
                provincia: null,
                centro: null
            }
        };
    },
    computed: {
        students() {
            return this.studentsList.filter(student => {
                if (!this.studentName) return true;

                return `${student.nombre.toLowerCase()} ${student.primer_apellido.toLowerCase()} ${student.segundo_apellido.toLowerCase()}`.includes(this.studentName.toLowerCase());
            });
        },
        filteredCenters() {
          const centers = this.centers.filter((c) => {
            return (
                c.name
                    .toString()
                    .toLowerCase()
                    .indexOf(this.filterName.toLowerCase()) >= 0
            )
          });

          return centers;
        }
    },
    methods: {
        openModal(student) {
            this.currentStudent = null;

            if(student) {
                this.formStudent = {
                    nombre: student.nombre,
                    primer_apellido: student.primer_apellido,
                    segundo_apellido: student.segundo_apellido,
                    pruebas: student.pruebas.split(', ').map(p => this.pruebas.find(pA => pA.prueba_id === Number(p))),
                    necesidades_especiales: student.necesidades_especiales,
                    tieneNecessidadesEspeciales: student.necesidades_especiales ? true : false
                };

                this.currentStudent = student;
            } else {
                this.formStudent = {
                    nombre: null,
                    primer_apellido: null,
                    segundo_apellido: null,
                    pruebas: [],
                    necesidades_especiales: null,
                    tieneNecessidadesEspeciales: false
                };
            }

            this.isModalActive = true;
        },
        formatearFecha(fecha) {
            if (!fecha) return '';

            const opciones = { day: 'numeric', month: 'long' };
            return new Date(fecha).toLocaleDateString('es-ES', opciones);
        },
        downloadCSV(){
            this.$store.commit("setShowSpinner", true);
            this.$store.dispatch("getAlmunosCSV", {
                id: this.personalData.center || this.filtersForm.provincia,
                isProvincia: this.personalData.center ? false : true
            }).then(response => {
                const blob = new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `alumnos_${this.personalData.center ? this.personalData.centerName.toLowerCase() : this.communitys.find(
            (c) => c.id === this.filtersForm.comunidad_autonoma
            ).provinces.find((p) => p.id === this.filtersForm.provincia).name}_${new Date().getFullYear()}.xlsx`); // Nombre del archivo
                document.body.appendChild(link);
                link.click();
                link.remove();
                
                window.URL.revokeObjectURL(url);

                this.$store.commit("setShowSpinner", false);
            }).catch((error) => {
                this.$store.commit("setShowSpinner", false);
            });
        },
        addPrueba(prueba){
            const index = this.formStudent.pruebas.findIndex(p => p.prueba_id === prueba.prueba_id);
            if(index === -1){
                this.formStudent.pruebas = this.formStudent.pruebas.filter(p => p.idioma !== prueba.idioma);
                this.formStudent.pruebas = this.formStudent.pruebas.filter(p => p.fecha_inicio !== prueba.fecha_inicio);

                this.formStudent.pruebas.push(prueba);
            } else {
                this.formStudent.pruebas.splice(index, 1);
            }
        },
        sumbitFormStudent() {
            const data = {
                nombre: this.formStudent.nombre,
                primer_apellido: this.formStudent.primer_apellido,
                segundo_apellido: this.formStudent.segundo_apellido,
                comunidad_autonoma_id: this.personalData.community,
                provincia_id: this.personalData.province,
                centro_id: this.personalData.center,
                pruebas: this.formStudent.pruebas.map(p => p.prueba_id),
                necesidades_especiales: this.formStudent.tieneNecessidadesEspeciales ? this.formStudent.necesidades_especiales : ''
            };

            this.isModalActive = false;
            this.$store.commit("setShowSpinner", true);

            if(this.currentStudent) {
                delete data.comunidad_autonoma_id;
                delete data.provincia_id;
                delete data.centro_id;

                this.$store.dispatch("editarAlumno", {
                    data: {
                        alumno: data,
                        alumnoId: this.currentStudent.id,
                        centroId: this.personalData.center
                    }
                }).then((d) => {
                    this.$store.commit("setShowSpinner", false);
                    this.getAlumnos();
                }).catch((error) => {
                    this.$store.commit("setShowSpinner", false);
                });
            } else {
                this.$store.dispatch("crearAlumno", {
                    data,
                }).then((d) => {
                    this.$store.commit("setShowSpinner", false);
                    this.getAlumnos();
                }).catch((error) => {
                    this.$store.commit("setShowSpinner", false);
                });
            }
        },
        confirmDelete() {
            this.$buefy.dialog.confirm({
                title: this.$t("TEACHER.DELETE_STUDENT"),
                message: this.$t("TEACHER.DELETE_STUDENT_TEXT", { nameStudent: `${this.currentStudent.nombre} ${this.currentStudent.primer_apellido} ${this.currentStudent.segundo_apellido}` }),
                confirmText: this.$t("TEACHER.DELETE"),
                cancelText: this.$t("TEACHER.CANCEL"),
                type: "is-danger",
                hasIcon: true,
                iconPack: "far",
                onConfirm: () => {
                    this.$store.dispatch("eliminarAlumno", {
                        data: {
                            alumnoId: this.currentStudent.id,
                            centroId: this.personalData.center
                        },
                    }).then((d) => {
                        this.isModalActive = false;
                        this.getAlumnos();
                    }).catch((error) => {
                        this.isModalActive = false;
                    });
                },
            });
        },
        getAlumnos() {
            if(this.personalData.center) {
                this.showCenterColumn = false;
                this.$store.commit("setShowSpinner", true);
                this.$store.dispatch("getAlumnosPorCentro", {
                    centro: this.personalData.center,
                }).then((alumnos) => {
                    this.studentsList = alumnos;
                    
                    this.$store.commit("setShowSpinner", false);
                }).catch((error) => {
                    this.studentsList = [];
                    this.$store.commit("setShowSpinner", false);
                });
            } else {
                if(this.filtersForm.provincia) {
                    this.showCenterColumn = true;
                    this.$store.commit("setShowSpinner", true);
                    this.$store.dispatch("getAlumnosPorProvincia", {
                        provincia: this.filtersForm.provincia,
                    }).then((alumnos) => {
                        this.studentsList = alumnos;
                        
                        this.$store.commit("setShowSpinner", false);
                    }).catch((error) => {
                        this.studentsList = [];
                        this.$store.commit("setShowSpinner", false);
                    });
                }
            }
        },
        validateRol(currentUser) {
            let parts = FORMAT_ROL.split("{}");
            const rolSuerProfesor = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.SUPERPROFESOR}${parts[2]}`;
            const rolProfesor = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.PROFESOR}${parts[2]}`;
            const hashProfesor = sha1(rolProfesor).toString();
            const hashSuperProfesor = sha1(rolSuerProfesor).toString();
            if (hashProfesor === currentUser.rol) {
                return ROLES.PROFESOR;
            } else if (hashSuperProfesor === currentUser.rol) {
                return ROLES.SUPERPROFESOR;
            } else {
                return null;
            }
        },
        selectCommunity() {
            this.provinces = this.communitys.find(
            (c) => c.id === this.filtersForm.comunidad_autonoma
            ).provinces;

            this.filtersForm.provincia = null;
            this.filtersForm.centro = null;
            this.filterName = "";
            this.centers = [];
            this.studentsList = [];
        },
        selectProvince() {
            this.centers = this.communitys.find(
            (c) => c.id === this.filtersForm.comunidad_autonoma
            ).provinces.find((p) => p.id === this.filtersForm.provincia).centros;

            this.filtersForm.centro = null;
            this.personalData.center = null;

            this.studentsList = [];
            this.filterName = "";

            this.$store.commit("setShowSpinner", true);
            this.$store.dispatch("getPruebasPorProvincia", {
                    provincia: this.filtersForm.provincia,
            }).then(pruebas => {
                this.$store.commit("setShowSpinner", false);
                this.pruebas = pruebas;

                this.getAlumnos();
            }).catch(error => {
                this.$store.commit("setShowSpinner", false);
            });
        },
        selectCentro(centro) {
            this.filtersForm.centro = (centro ? centro.id : null);

            if(this.filtersForm.centro) {
                this.personalData.center = this.filtersForm.centro;
                this.personalData.community = this.filtersForm.comunidad_autonoma;
                this.personalData.province = this.filtersForm.provincia;
            } else {
                this.personalData.center = null;
                this.studentsList = [];
            }

            this.getAlumnos();
        }
    },
    created() {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const decodedToken = jwt_decode(currentUser.access_token);
        currentUser.id = decodedToken.user_claims['id'];
        currentUser.rol = decodedToken.user_claims['rol'];
        let rolUser = this.validateRol(currentUser);

        this.isSuperProfesor = rolUser === "superprofesor";

        if (this.isSuperProfesor) {
            this.$store.dispatch("getRegisterForm").then((data) => {
                this.communitys = data;
            }).catch((error) => {
            });
        }

        this.$store.dispatch("getFase").then(fases => {
            fases = fases.find(obj => obj.activa);
            if (!this.isSuperProfesor && (fases.codigo !== FASES.INSCRIPCION && fases.codigo !== FASES.LOGIN_PREVIO)) {
                this.isReadOnly = true;
            }

            if(fases.codigo === FASES.FINAL){
                this.downloadDiplomes = true;
            }
        });

        this.$store.commit("setShowSpinner", true);
        this.$store.dispatch("getUser").then(user => {
            this.personalData.name = `${user.nombre} ${user.primer_apellido} ${user.segundo_apellido}`;
            this.personalData.phone = user.telefono;
            this.personalData.email = user.email;
            this.personalData.province = user.provincia_id;
            this.personalData.community = user.comunidad_autonoma_id;
            this.personalData.center = user.centro_id;
            this.personalData.centerName = user.nombre_centro;
            this.$store.commit("setShowSpinner", false);

            if(!this.isSuperProfesor) {
                this.$store.commit("setShowSpinner", true);

                this.$store.dispatch("getPruebasPorProvincia", {
                    provincia: this.personalData.province,
                }).then(pruebas => {
                    this.$store.commit("setShowSpinner", false);
                    this.pruebas = pruebas;
    
                    this.getAlumnos();
                }).catch(error => {
                    this.$store.commit("setShowSpinner", false);
                });
            }
        }).catch(error => {
            this.$store.commit("setShowSpinner", false);
        });
    }
};
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.datos {
    background-color: white;
    margin-top: -150px;
    padding: 20px;
}

.titulo-seccion {
    margin-top: 4px;
    margin-bottom: 16px;
    font-size: 25px;
    width: -webkit-fill-available;

    .center {
        font-size: 16px;
    }
}

.header-buttons{
    background-color: $primary;
    align-items: end;
    display: flex;
    width: calc(100% + 16px);
    margin-left: -8px;
    padding-right: 20px;
    gap: 20px;

    .btn {
        width: fit-content;
        background-color: white;
        color: black;
        margin-bottom: 0px;
        height: fit-content;
        border-radius: 10px;
    }
}

.contenido {
    padding-bottom: 120px;
}

.titulo-seccion div:last-child {
    height: 5px;
}

.table {
  width: 100%;
  margin-bottom: 25px;
  border-collapse: separate;
  border-spacing: 0px 12px;
}

.table td,
.table th {
  border: none;
  padding: 1em 1.25em;
}

.table tbody tr:hover {
  cursor: pointer;
}

.table tbody tr {
  background-color: $background-box;

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .rounded-border {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.icons-actions {
    width: 100px;
    background-color: white;
}

.parte-superior {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 20px;
}

.container-modal {
  min-width: 700px;
  padding: 20px;
  background-color: white;

  .btn{
    border-radius: 10px;
  }
  
  .btn-add{
    background-color: $primary;
    color: white;
  }

  .btn-trash:hover{
    background-color: $primary;
    color: white;
  }

  .btn-cancel{
    background-color: #cacaca;
    color: black;
  }

  hr{
    width: calc(100% + 40px);
    margin-left: -20px;
    background-color: $primary;
    height: 8px;
  }

  .prueba-select {
    display: flex;
    align-items: center;
    cursor: pointer;

    div:first-child {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: 20px;
      background-color: $background-box;
    }

    div:last-child {
        padding: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 10px;
        width: 350px;
        background-color: $background-box;
    }
  }

  .prueba-selected {
    div:first-child {
      background-color: #e85656;
    }

    div:last-child {
        background-color: #e85656;
        color: white;
    }
  }

  .btn-dropdown{
    background-color: $background-box;
    border-radius: 25px;
  }
}
</style>
